$pnp.setup({
    headers: {
        "Accept": "application/json; odata=verbose"
    }
});

Vue.component(`premios-reconhecimentos-home`, {
    template:`
    <element>
        <div class="premios-home">
            <div class="premio" v-for="item in premios">
                <a :href="getDispItem(item)" :title="item.Title" v-if="item.LinkImagem">
                    <img :src="item.LinkImagem" :alt="item.Title" class="w-100 img-fluid" />
                </a>
            </div>
        </div>
        <div class="buttons" v-show="premios.length > 5">
            <button class="prev bg-primary text-white" type="button"><i class="fas fa-chevron-left"></i></button>
            <button class="next bg-primary text-white" type="button"><i class="fas fa-chevron-right"></i></button>
        </div>
    </element>
    `,
    data() {
        return {
            listURL: `${_spPageContextInfo.webServerRelativeUrl}lists/Premios`,
            premios: []
        }
    },
    methods: {
        getItems(){
            return $pnp.sp.web.getList(this.listURL).items
                .select('*')
                .top(100)
                .orderBy('Ano', false)
                .get().then((results) => {
                    Vue.set(this, 'premios', results)
                    this.applySlick()
                }).catch((err) => {
                    this.threatError(err, 'Erro ao obter Premios')
                })
        },
        threatError(err, msg){
            return swal({
                type: 'error',
                title: 'Oops!',
                text: msg || err
            });
        },
        applySlick(){
            Vue.nextTick(() => {
                $('.premios-home').slick({
                    slidesToShow: 5,
                    slidesToScroll: 1,
                    arrows: false,
                    dots: false,
                    autoplay:true,
                    prevArrow: $('.prev'),
                    nextArrow: $('.next'),
                    infinite: true
                });
            })
        },
        getDispItem(item){
            return `${_spPageContextInfo.webServerRelativeUrl}pages/premio.aspx?ID=${item.Id}`;
        }
    },
    created() {
        this.getItems();
    },
});