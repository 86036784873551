$pnp.setup({
    headers: {
        "Accept": "application/json; odata=verbose"
    }
});

require('./categorias-noticias');

Vue.component('noticias-home', {
    data: function () {
        var listName = 'Noticias'
        return {
            listName: listName,
            listURL: `${_spPageContextInfo.siteServerRelativeUrl}lists/${listName}`,
            noticiasHome: [],
            moment: moment,
            categoria: null,
            firstCategoria: `informa`,
            isLoading: false
        }
    },
    template: `
    <element>
        <categoria-noticias @change="onClickCategoria" />
  
        <template v-if="noticiasHome.length">
            <div class="carousel noticias-publicacoes row">
                <div class="noticia" v-for="item in noticiasHome">
                    <div class="picture">
                        <figure>
                            <a :href="getDispUrl(item)" :title="isEnglish ? item.TitleEN : item.Title">
                                <img :src="isEnglish ? getImage(item.LinkImagem2) : getImage(item.LinkImagem)" :alt="item.Title" class="border-before custom-shadow w-100"/>
                            </a>
                        </figure>
                    </div>
                    <h3 class="mb-3"><a :href="getDispUrl(item)" :title="isEnglish ? item.TitleEN : item.Title">{{ isEnglish ? truncateString(item.TitleEN, 70) : truncateString(item.Title, 70)}}</a></h3>
                    <div class="category-date text-uppercase">
                        <span class="origem text-secondary font-weight-bold" v-if="item.Origem0.results.length">{{ isEnglish ? item.Origem0.results[0].TitleEn : item.Origem0.results[0].Title }}</span>
                        <span class="date">{{ moment(item.Data).format('DD.MM.YYYY') }}</span>
                    </div>
                    <p>{{ isEnglish ? truncateString(item.ResumoEN, 120) : truncateString(item.Resumo, 120) }} 
                        <a :href="getDispUrl(item)" :title="isEnglish ? item.TitleEN : item.Title" class="see-more">{{ isEnglish ? 'see more' : 'leia mais' }}
                            <i class="fas fa-plus"></i> 
                        </a>
                    </p>
                </div>
            </div>
            <div class="buttons" v-show="noticiasHome.length > 4">
                <button class="prev bg-primary text-white" type="button"><i class="fas fa-chevron-left"></i></button>
                <button class="next bg-primary text-white" type="button"><i class="fas fa-chevron-right"></i></button>
            </div>

            <div class="text-center">
                <a v-if="isEnglish ? 'See all' : 'Ver todas'" :href="verTodasNoticias()" 
                    :title="isEnglish ? 'See all' : 'Ver todas'" 
                    class="btn border text-uppercase mt-4 mt-sm-2 btn-secondary"> 
                    {{ isEnglish ? 'See all' : 'Ver todas' }}
                </a>
            </div>
        </template>
        <template v-else>
            <p class="text-center">{{ isEnglish ? 'Sorry, there is no news for this category.' : 'Desculpe, não existem notícias para esta categoria.' }}</p>
        </template>
    </element>
    `,
    created: function () {
        this.loadResults()
    },
    mixins: [pnpMixin, translateMixin],
    props: {
        dadosBtn: {required: false}
    },
    methods: {
        loadResults(categoria) {            
            if(categoria){
                this.categoria = categoria;
                return this.getData(this.categoria);
            }else {
                this.categoria = `${this.firstCategoria}`;
                return this.getData(this.categoria);
            }
            
        },
        getData (cat) {
            var today = moment().format('YYYY-MM-DD[T]HH:mm:ss[Z]');
            this.noticiasHome = null;
            this.isLoading = true;
            var filtroCat = '';
            if(cat){
                this.categoria = cat
                filtroCat = ` and AuxCategoria/SeoURL eq '${this.categoria}'`;
            }else {
                this.categoria = `${this.firstCategoria}`;
            }

            return $pnp.sp.web.getList(this.listURL).items
                .expand(`AuxCategoria, Idioma, Origem0`)
                .filter(`DestaqueHome eq 'Sim' and DataPublicacao le datetime'${today}' and Idioma/Decimal eq '${CurrentLanguageDecimal}'${filtroCat}`)
                .select('*, AuxCategoria/Title, AuxCategoria/Id, AuxCategoria/SeoURL, AuxCategoria/TitleEN, Idioma/Title, Idioma/Decimal, Origem0/Title, Origem0/TitleEn, Origem0/SeoURL')
                .orderBy('Data', false)
                .top(100)
                .get().then((results) => {
                    this.$set(this, 'noticiasHome', results);
                    console.log(">>>>", results)
                    this.applySlick();
                }).catch((err) => {
                    this.threatError(err, 'Erro ao obter notícias')
                })
        },
        getDispUrl(item){
            //return `${_spPageContextInfo.webServerRelativeUrl}pages/detalhe-insight.aspx?title=${item.SeoURL}`
            return `${_spPageContextInfo.siteAbsoluteUrl}/cesconbarrieuinsights/${item.SeoURL}`
        },
        applySlick(){
            this.isLoading = false;
            $('.noticias-publicacoes').slick('unslick');
           $(document).ready(function(){
                Vue.nextTick(() => {
                    $('.noticias-publicacoes').slick({
                        slidesToShow: 4,
                        slidesToScroll: 1,
                        arrows: true,
                        dots:false,
                        infinite: false,
                        swipe:false,
                        prevArrow: $('.prev'),
                        nextArrow: $('.next'),
                        responsive:[{
                            breakpoint: 996,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll:1,
                                arrows: true,
                                swipe:true,
                            }
                        }]
                    });
                })
           })
        },
        threatError: function (msg, data) {
            console.error('error - ' + msg + ' - ' + data);
        },
        getCustomData(data) {
            if (!this.isEnglish)
                return moment(data).format('DD . MMM . YYYY');
            else
                return moment(data).lang("en-us").format('MMM . DD . YYYY');
        },
        onClickCategoria(cat) {
            this.$emit('change', cat);
            this.loadResults(cat); 
        },
        getImage(img){
            var image = '';
            if(img){
                image = `${img}`
            }else {
                image = `${_spPageContextInfo.webServerRelativeUrl}PublishingImages/Noticias/cescon-barrieu-imagem-noticia-branco.jpg`
            }

            return `${image}?RenditionID=8`
        },
        verTodasNoticias(){
            var param = '';
            if(this.categoria){
                param = `#category=${this.categoria}`;
            }
            return `${_spPageContextInfo.siteAbsoluteUrl}/cesconbarrieuinsights${param}`
            
        }
    }
});