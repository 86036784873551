$pnp.setup({
  headers: {
    Accept: "application/json; odata=verbose",
  },
});

require(`../../mixins/translate`);

Vue.component(`home-popup-new`, {
  mixins: [translateMixin],
  data() {
    return {
      moment: moment,
      dataExpiracao: ``,
    };
  },
  template: `
      <element id="modalHomePopupSection">
        <div class="overlay">
          <div class="modal-cookies" id="modalCookies">
            <h2 class="text-white font-weight-bold h4">
              <span>
                <b v-if="!isEnglish">Atenção!</b>
                <b v-else>Attention!</b>
              </span>
            </h2>
            <div class="txt-modal mb-3">
              <p v-if="!isEnglish" class="text-white">O Cescon Barrieu alerta que todas as suas comunicações oficiais são realizadas exclusivamente por e-mails corporativos com o domínio @cesconbarrieu.com.br. Caso receba tentativas de contato em nome do escritório por WhatsApp ou via e-mail de um domínio diferente, seja para negociação, solicitação de informações ou envio de boletos, ignore a mensagem. </p>
              <p v-else>Cescon Barrieu warns that all its official communications are carried out exclusively via corporate emails with the domain @cesconbarrieu.com.br. If you receive attempts to contact the office on behalf of the office via WhatsApp or via email from a different domain, whether for negotiation, requesting information or sending invoices, ignore the message.</p>
              <div>
                  <button id="modalHomeConfirm" type="button" class="btn bg-white rounded mr-1 p-2">{{ isEnglish ? 'Accept' : 'Aceito' }}</button>
              </div>
            </div>
          </div>
        </div>
      </element>
      `,
  created() {
    $(document).ready(function () {
      // Check if the popup has been shown this session
      if (!sessionStorage.getItem("ScamWarningShown")) {
        // Check if the user is on the home page
   
          $(".modalHomePopupSection").show();
          $("body").addClass("overflow");
       
      }
      $('#modalHomeConfirm').on('click', function () {
        sessionStorage.setItem("ScamWarningShown", "true");
        $('#modalHomePopupSection').hide();
        $('body').removeClass('overflow');
    });
    });
  },
});
