$pnp.setup({
    headers: {
        "Accept": "application/json; odata=verbose"
    }
});

// importando os mixins com os metodos do banner - foram separados porque havia muita informação então preferi separar.
require('./banner/banner-texts');
require('./banner/banner-scripts');

Vue.component('component-banner',{
    template:`
    <element>
        <div class="banner-home">
            <!-- is mobile -->
            <template v-if="isMobile">
                <div class="slide-banner">
                    <div class="banner" :class="item.Overlay ? 'overlay' : ''"  v-for="item in bannerHome" v-if="item.CategoriaBanner == 'Mobile'">
                        <div class="img-banner slide-image" :style="getBackgroundImage(item.LinkImagem)"></div>

                        <!-- text banner -->
                        <div class="container">
                            <div class="row">
                                <div class="col-12 col-sm-12">
                                    <div class="text-banner text-center">
                                        <h1 v-bind:style="estilizarTitulo(item)">
                                            {{ !isEnglish ? item.Title : (item.TitleEN || item.Title) }}<br/>
                                        </h1>
                                        <h2 class="subtitle" v-bind:style="estilizarSubTitulo(item)">
                                            {{ !isEnglish ? item.SubTitulo : (item.SubTituloEN || item.SubTitulo) }}
                                        </h2>
                                        <div v-bind:style="estilizarTexto(item)"  v-show="item.Descricao" class="text-white mb-3 banner-descricao" v-html="!isEnglish ? item.Descricao : (item.DescricaoEN || item.Descricao)"></div>
                                        <div class="btn-banner-home" v-if="!isEnglish ? item.TextoBotao : item.TextoBotaoEN">
                                            <a :href="getURL(!isEnglish ? item.URL : item.URLEN)" 
                                                :title="!isEnglish ? item.TextoBotao : item.TextoBotaoEN" 
                                                class="btn d-sm-inline border mt-4" 
                                                :style="estilizarBotao(item)"
                                                target="_blank"
                                            >
                                                {{ isEnglish ? item.TextoBotaoEN : item.TextoBotao }}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- /text banner -->
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="slide-banner">
                    <!-- for-each items-->
                    <div class="banner" v-for="item in bannerHome" :class="getCustomClass(item)" v-bind:onclick="(!item.TituloURL && item.URL) ? openURL(item) : undefined">
                        <!-- template imagem -->
                        <template v-if="item.TipoBanner == 'Imagem'">
                            <template v-if="(!isEnglish ? item.TextoBotao : item.TextoBotaoEN) && (!isEnglish ? item.URL : item.URLEN)">
                                <a :href="getURL(!isEnglish ? item.URL : (item.URLEN || item.URL))" 
                                    :title="!isEnglish ? item.TextoBotao : item.TextoBotaoEN"
                                    target="_blank"
                                    class="mt-4"
                                    :style="estilizarBotao(item)"
                                >
                                    <div class="img-banner slide-image" :style="getBackgroundImage(item.LinkImagem)"></div>
                                </a>
                            </template>
                            <template v-else>
                                <div class="img-banner slide-image" :style="getBackgroundImage(item.LinkImagem)"></div>
                            </template>
                        </template>
                        <!-- /template imagem -->

                        <!-- template vimeo -->
                        <template v-if="item.TipoBanner == 'Vídeo' && item.TipoVideo == 'Vimeo'">
                            <div class="slide-video slide-media" data-video-start="4">
                                <iframe 
                                    class="embed-player slide-media" 
                                    :src="getUrlVideo(item.URLVideo)" 
                                    width="980" height="520" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen>
                                </iframe>
                            </div>
                        </template>
                        <!-- /template vimeo -->

                        <!-- tempalte youtube -->
                        <template v-if="item.TipoBanner == 'Vídeo' && item.TipoVideo == 'Youtube'">
                            <iframe 
                                class="embed-player slide-media" 
                                :src="getUrlVideo(item.URLVideo)" 
                                width="980" height="520" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen>
                            </iframe>
                        </template>
                        <!-- /template youtube -->

                        <!-- template video local -->
                        <template v-if="item.TipoBanner == 'Vídeo' && item.TipoVideo == 'Local'">
                            <video class="slide-video slide-media" loop muted autoplay preload="metadata" :poster="item.LinkImagem">
                                <source :src="getUrlVideo(item.URLVideo)"  type="video/mp4" />
                            </video>
                        </template>
                        <!-- /template video local -->

                        <!-- text banner -->
                        <div class="container">
                            <div class="row">
                                <div class="col-12 col-sm-12">
                                    <div class="text-banner text-center">
                                        <h1 v-bind:style="estilizarTitulo(item)">
                                            {{ !isEnglish ? item.Title : (item.TitleEN || item.Title) }}<br/>
                                        </h1>
                                        <h2 class="subtitle" v-bind:style="estilizarSubTitulo(item)">
                                            {{ !isEnglish ? item.SubTitulo : (item.SubTituloEN || item.SubTitulo) }}
                                        </h2>
                                        <div v-bind:style="estilizarTexto(item)" v-show="item.Descricao" class="text-white mb-3 banner-descricao" v-html="!isEnglish ? item.Descricao : (item.DescricaoEN || item.Descricao)"></div>
                                        <div class="btn-banner-home" v-if="!isEnglish ? item.TextoBotao : item.TextoBotaoEN">
                                            <a :href="getURL(!isEnglish ? item.URL : (item.URLEN || item.URL))" 
                                                :title="!isEnglish ? item.TextoBotao : item.TextoBotaoEN" 
                                                class="btn d-sm-inline border mt-4" 
                                                :style="estilizarBotao(item)"
                                                target="_blank"
                                            >
                                                {{ isEnglish ? item.TextoBotaoEN : item.TextoBotao }}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- /text banner -->
                    </div>
                    <!-- /for-each items -->

                </div>
                <div class="bt-scroll">
                    <div class="container">
                        <a href="#quem-somos" class="button-scroll" title="Conheça mais sobre o escritório">
                            <i class="fas fa-chevron-down"></i>
                        </a>
                    </div>
                </div>
            </template>
        </div>
    </element>
    `,
    mixins: [translateMixin, methodsBannerText, methodsBannerScripts],
    created: function(){
        this.getData()
            .then(this.applyEvents)
            .then(this.scriptsBanner)
    },
    methods: {
        getData: function(){
            var today = moment().format('YYYY-MM-DD[T]HH:mm:ss[Z]');

            var web = new $pnp.Web(_spPageContextInfo.siteAbsoluteUrl);
            var customFilter = `${this.isMobile ? " and CategoriaBanner eq 'Mobile'" : " and CategoriaBanner eq 'Desktop'"}`;

            return web.getList(this.listUrl).items
                .filter(`DataPublicacao le datetime'${today}' and AuxIdioma/LCID eq '${CurrentLanguageLCID}'${customFilter}`)
                .select('*, CategoriaBanner, AuxIdioma/Title, CorTitulo/Cor, CorSubTitulo/Cor, CorBotao/Cor')
                .expand('AuxIdioma, CorTitulo, CorSubTitulo, CorBotao')
                .orderBy('Ordem',true)
                .top(100)
                .get().then((banners) => {
                    Vue.set(this, 'bannerHome', banners);
                }).catch((err) => {
                    this.threatError(err, `Erro ao obter  dados da lista ${this.listName}`);
                })
        },
        isSafari() {
            var isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
            var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
            if (isSafari && iOS) {
                $('#banner').css({
                    height: 'calc(100vh - 280px)'
                });
            } else if(isSafari) {
                //
            }
        },
        applyEvents: function(){
                //banner + busca height automatico
                var heightDynamic = window.innerHeight+'px';
                var userAgent = window.navigator.userAgent;

                if(window.innerWidth <= 992){
                    heightDynamic = (window.innerHeight - 90)+'px';
                    this.isSafari();
                }

                $('#banner-height').height(heightDynamic);
                // banner home - slick slider
                Vue.nextTick(function(){
                    $('.banner[bg-image]').each(function(){
                        var imgURL = $(this).attr('bg-image');
                        $(this).css('style',`${imgURL}`)
                    })
                })
        },
        openURL(item){
            if(this.isEnglish){
                return `window.open('${item.URLEn}')`;
            }else{
                return `window.open('${item.URL}')`;
            }
        },
        threatError: function(msg,data){
            console.error('error - ' + msg + ' - ' + data);
        },
        getBackgroundImage(image){
            return `background-image:url('${image}')`;
        },
        getURL(URL){
            if(URL){
                if(URL == '#'){
                    return false
                }else {
                    return URL
                }
            }
        },
        corBotao(cor){
            if(cor){
                return `background-color:${cor}`;
            }
        },
        getCustomClass(item){
            if(item.Overlay){
                return `overlay`
            }
            if(item.TipoBanner == 'Vídeo'){
                if(item.TipoVideo == 'Vimeo'){
                    return 'vimeo'
                }else if(item.TipoVideo == 'Youtube'){
                    return 'youtube'
                }else {
                    return 'video'
                }
            }else {
                return 'image'
            }
        },
        getUrlVideo(url){
            if(url.match(/vimeo/i)){
                var id = url.split('vimeo.com/')[1];
                return `https://player.vimeo.com/video/${id}?api=1&byline=0&portrait=0&title=0&background=1&mute=1&loop=1&autoplay=0&id=${id}`
            }else if(url.match(/youtube/i) || url.match(/youtu.be/i)){
                var id = url.split('watch?v=')[1];
                return `https://www.youtube.com/embed/${id}?enablejsapi=1&controls=0&fs=0&iv_load_policy=3&rel=0&showinfo=0&loop=1&start=1`
            }else {
                return url;
            }
        }
    },
    data: function() {
        var listName = 'Banner'
        return {
            listName: listName,
            listUrl: `${_spPageContextInfo.siteServerRelativeUrl}lists/${listName}`,
            bannerHome: [],
            isMobile: window.innerWidth >= 1024 ? false : true
        }
    }
})

var app = new Vue({
    el: '#component-banner',
    template: '<component-banner/>'
})