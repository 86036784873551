$pnp.setup({
    headers: {
        "Accept": "application/json; odata=verbose"
    }
});

Vue.component(`enderecos-footer`, {
    mixins: [translateMixin],
    template: `
    <div class="enderecos-footer row mb-4">
        <div class="col-sm-4 escritorio text-center" v-for="item in escritorios">
            <img :src="item.LinkImagem2" :alt="item.Title" v-show="item.LinkImagem2" class=""/>
            <h3 class="text-uppercase font-weight-bold">{{ isEnglish ? item.TitleEN : item.Title }} - <span class="text-primary">{{ item.Sigla }}</span></h3>
            <div v-show="item.Descricao" v-html="item.Descricao"></div>
            <a :href="item.URL" class="" target="_blank" title="Ver no mapa"><i class="fas fa-map-marker-alt text-primary"></i></a>
        </div>
    </div>
    `,
    data() {
        return {
            listURL: `${_spPageContextInfo.webServerRelativeUrl}lists/Unidades`,
            escritorios: []
        }
    },
    methods: {
        getItems(){
            return $pnp.sp.web.getList(this.listURL).items
                .select(`*`)
                .orderBy('Ordem', true)
                .top(10)
                .get().then((results)=> {
                    Vue.set(this, 'escritorios', results)
                }).catch((err) => {
                    this.threatError(err, 'Erro ao obter escritórios')
                })
        },
        threatError(err, msg){
            return Swal.fire({
                type: 'error',
                title: 'Oops!',
                text: err || msg
            })
        },
        applyEvents(){
            Vue.nextTick(() => {
                $('.enderecos-footer').slick({
                    slidesToShow: 5,
                    slidesToScroll:1,
                    swipe:false,
                    dots: false,
                    arrows: true,
                    autoplay: false
                })
            })
        }
    },
    created() {
        this.getItems().then(this.applyEvents)
    }
});