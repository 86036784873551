
require('./noticias-home');                     // noticias
require('./premios-reconhecimentos-home');      // premios e reconhecimentos home
require('./enderecos-footer');
require('./home-popup')            // enderecos home
Vue.component(`sections-home`, {
    mixins: [translateMixin],
    data() {
        var listName = 'HomeConteudo';
        return {
            listName: listName,
            listURL: `${_spPageContextInfo.webServerRelativeUrl}lists/${listName}`,
            homeConteudo: []
        }
    },
    template: `
    <element>
        <template v-for="item in homeConteudo">
            <section :id="getIds(item)" :class="getClass(item)" :style="getPicture(item.LinkImagem)">
                <div class="container">
                    <div class="text-center mb-5">
                        <!--  -->
                        <h2 v-if="item.LocalHome == 'Nosso Escritório' || item.LocalHome == 'Nossos Advogados' ||  item.LocalHome == 'Notícias'" :class=" item.LocalHome == 'Nosso Escritório' || item.LocalHome == 'Nossos Advogados' ? 'mb-4 font-weight-bold text-primary text-uppercase small' : ''">
                            <span :class="item.LocalHome == 'Nosso Escritório' || item.LocalHome == 'Nossos Advogados' ? 'border-bottom border-primary pb-2' : ''">
                                {{ isEnglish ? item.TitleEn : item.Title }}
                            </span>
                        </h2>
                        
                        <h3 :class="item.LocalHome == 'Nossos Escritórios'  ? 'text-primary banner-section' : 'text-white banner-section'" class="mb-3" v-show="isEnglish ? item.ConteudoEn : item.Conteudo">{{ isEnglish ? item.ConteudoEn : item.Conteudo }}</h3>
                      
                    </div>
                    <template v-if="item.LocalHome == 'Notícias'">
                        <noticias-home></noticias-home>
                    </template>
                    <!--
                    <template v-if="item.LocalHome == 'Ranking & Reconhecimentos'">
                        <premios-reconhecimentos-home></premios-reconhecimentos-home>
                    </template>
                    
                    <template v-if="item.LocalHome == 'Nossos Escritórios'">
                        <enderecos-footer></enderecos-footer>
                    </template>
                      -->
                    <div class="text-center" v-if="item.LocalHome == 'Nosso Escritório' || item.LocalHome == 'Nossos Advogados'">
                        <a v-show="item.URL" v-if="isEnglish ? item.TitleBotaoEn : item.TitleBotao" :href="item.URL" 
                            :title="isEnglish ? item.TitleBotaoEn : item.TitleBotao" 
                            class="btn border text-uppercase mt-2 btn-secondary"> 
                            {{ isEnglish ? item.TitleBotaoEn : item.TitleBotao }}
                        </a>
                    </div>
                </div>
            </section>
        </template>
        <home-popup-new/>
    </element>
    `,
    methods: {
        getItems() {
            return $pnp.sp.web.getList(this.listURL).items
                .select(`*`)
                .filter(`Ativo eq 'Sim'`)
                .top(100)
                .orderBy(`Ordem`, true)
                .get().then((results) => {
                    Vue.set(this, 'homeConteudo', results);
                }).catch((err) => {
                    this.threatError(err, 'Erro ao obter itens de Home Conteúdo.')
                })
        },
        threatError(err, msg) {
            return swal({
                type: `error`,
                text: msg || err,
                title: 'Oops!'
            });
        },
        getIds(item) {
            if (item.LocalHome == 'Nosso Escritório') {
                return 'quem-somos'
            } else if (item.LocalHome == 'Notícias') {
                return 'noticias'
            } else if (item.LocalHome == 'Nossos Advogados') {
                return 'profissionais'
            }
            // else if(item.LocalHome == 'Ranking & Reconhecimentos'){
            //     return 'premios-reconhecimentos'
            // }else{
            //     return 'enderecos'
            // }
        },
        getClass(item) {
            // if(item.LocalHome == 'Notícias' || item.LocalHome == 'Nossos Escritórios' ){
            //     return 'bg-light'
            // }
        },
        getPicture(img) {
            if (img) {
                return `background-image:url(${img});`
            } else {
                return ``;
            }
        }
    },
    created() {
        this.getItems();
    },
});
var app = new Vue({
    el: `#sections-home`,
    template: `<sections-home/>`
})