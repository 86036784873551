$pnp.setup({
    headers: {
        "Accept": "application/json; odata=verbose"
    }
});

require('../../mixins/translate');

Vue.component('busca-home-app', {
    mixins: [translateMixin],
    data() {
        return {
            mostrarBuscaAvancada: false,
            filtros: {
                keywords: null
            }
        }
    },
    template: `
    <div class="search-profissionais">
        <div class="row">
            <div class="col-sm-3 offset-sm-3 form-group">
                <input type="text" class="form-control rounded-0 icon-search" :placeholder="isEnglish? 'Search' : 'Buscar'" v-model="filtros.keywords" @keypress.enter.prevent="gotoSearchPage()"/>
            </div>
            <div class="col-sm-2 form-group">
                <button class="btn btn-primary btn-block rounded-0" type="button" @click="gotoSearchPage()">{{ isEnglish ? 'Search' : 'Buscar' }}</button>
            </div>
            <!--
            <div class="col-sm-2 form-group">
                <button class="btn btn-block btn-secondary rounded-0 border border-white" type="button" @click="showBuscaAvancada"><i class="fas fa-plus"></i> Busca avançada</button>
            </div>
            -->
        </div>
        <div class="row">
            <div class="col-12 bg-dark p-4" id="busca-avancada" v-show="mostrarBuscaAvancada">
                <h4 class="text-white mb-3">Pesquisa avançada <i class="fas fa-times close justify-content-end" @click="closeBuscaAvancada"></i></h4>
                <ul class="col-12 alfabeto nav mt-3">
                    <li class="nav-item"><a href="" class="nav-link">A</a></li>
                    <li class="nav-item"><a href="" class="nav-link">B</a></li>
                    <li class="nav-item"><a href="" class="nav-link">C</a></li>
                    <li class="nav-item"><a href="" class="nav-link">D</a></li>
                    <li class="nav-item"><a href="" class="nav-link">E</a></li>
                    <li class="nav-item"><a href="" class="nav-link">F</a></li>
                    <li class="nav-item"><a href="" class="nav-link">G</a></li>
                    <li class="nav-item"><a href="" class="nav-link">H</a></li>
                    <li class="nav-item"><a href="" class="nav-link">I</a></li>
                    <li class="nav-item"><a href="" class="nav-link">J</a></li>
                    <li class="nav-item"><a href="" class="nav-link">K</a></li>
                    <li class="nav-item"><a href="" class="nav-link">L</a></li>
                    <li class="nav-item"><a href="" class="nav-link">M</a></li>
                    <li class="nav-item"><a href="" class="nav-link">N</a></li>
                    <li class="nav-item"><a href="" class="nav-link">O</a></li>
                    <li class="nav-item"><a href="" class="nav-link">P</a></li>
                    <li class="nav-item"><a href="" class="nav-link">Q</a></li>
                    <li class="nav-item"><a href="" class="nav-link">R</a></li>
                    <li class="nav-item"><a href="" class="nav-link">S</a></li>
                    <li class="nav-item"><a href="" class="nav-link">T</a></li>
                    <li class="nav-item"><a href="" class="nav-link">U</a></li>
                    <li class="nav-item"><a href="" class="nav-link">V</a></li>
                    <li class="nav-item"><a href="" class="nav-link">X</a></li>
                    <li class="nav-item"><a href="" class="nav-link">Y</a></li>
                    <li class="nav-item"><a href="" class="nav-link">W</a></li>
                    <li class="nav-item"><a href="" class="nav-link">Z</a></li>
                </ul>
                <div class="row form-group">
                    <div class="col-sm-6">
                        <select class="custom-select rounded-0">
                            <option value="null" selected="selected">Áreas de Atuação</option>
                        </select>
                    </div>
                    <div class="col-sm-6">
                        <select class="custom-select rounded-0">
                            <option value="null" selected="selected">Posição</option>
                        </select>
                    </div>
                </div>
                <div class="row form-group">
                    <div class="col-sm-6">
                        <select class="custom-select rounded-0">
                            <option value="null" selected="selected">Escritório</option>
                        </select>
                    </div>
                    <div class="col-sm-6">
                        <select class="custom-select rounded-0">
                            <option value="null" selected="selected">Idioma</option>
                        </select>
                    </div>
                </div>
                <div class="row form-group">
                    <div class="col-sm-6">
                        <select class="custom-select rounded-0">
                            <option value="null" selected="selected"></option>
                        </select>
                    </div>
                    <div class="col-sm-6">
                        <button class="btn d-block btn-primary" type="button"><i class="fas fa-search-plus"></i> {{ isEnglish ? 'Search' : 'Buscar' }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    `,
    methods: {
        showBuscaAvancada() {
            this.mostrarBuscaAvancada = true;
        },
        closeBuscaAvancada() {
            this.mostrarBuscaAvancada = false;
        },
        gotoSearchPage() {
            window.location.href = `${_spPageContextInfo.siteAbsoluteUrl}/busca#keyword=${this.filtros.keywords || ''}`
        }
    },
    created() {

    },
});

var app = new Vue({
    el: `#busca-home-app`,
    template: `<busca-home-app/>`
})
