$pnp.setup({
    headers: {
        "Accept": "application/json; odata=verbose"
    }
});

Vue.component(`categoria-noticias`, {
    mixins: [translateMixin],
    template: `
        <element>
            <ul class="nav nav-category justify-content-center mb-3">
                <li class="nav-item" v-for="(item, i) in categorias">
                    <a href="javascript:" 
                        :title="isEnglish ? item.TitleEn : item.Title" 
                        :class="selectedCat == item.SeoURL || (!selectedCat && item.Ordem == 4) ? 'active' : ''" 
                        @click="onClickCategoria(item.SeoURL)"
                        class="nav-link">{{ isEnglish ? item.TitleEN : item.Title }}
                    </a>
                </li>                  
            </ul>
        </element>
    `,
    methods: {
        getCategorias(){
            return $pnp.sp.web.getList(this.listURL).items
                .filter(`Ativo eq 1`)
                .select(`*`)
                .orderBy(`Ordem`, true)
                .top(100)
                .get().then((results) => {
                    Vue.set(this, 'categorias', results)
                }).catch((err) => {
                    this.threatError(err, `Erro ao obter informações de ${this.listName}`)
                })
        },
        threatError(err, msg){
            return swal({
                type: 'error',
                text: msg || err,
                title: 'Oops!'
            });
        },
        onClickCategoria(id) {
            this.$emit('change', id);
            this.selectedCat = id;
        }
    },
    data() {
        var listName = `AuxCategoriaNoticia`;
        return {
            listName: listName,
            categorias: [],
            listURL: `${_spPageContextInfo.webServerRelativeUrl}lists/${listName}`,
            selectedCat: 0
        }
    },
    created() {
        this.getCategorias()
    },
})